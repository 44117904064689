"use client";

import { flexRender, HeaderGroup, Table } from "@tanstack/react-table";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { SortButtonGroup } from "../SortButtonGroup/SortButtonGroup";

interface DataTableProps<T> {
  data: Table<T>;
  onClickRow?: (_: T) => void;
  loading?: boolean;
  component?: string;
  allowClientSorting?: boolean;
  className?: string;
  noDataElement?: ReactNode;
}

function sumColSpans<T>(headerGroup: HeaderGroup<T>) {
  const colspanSum = headerGroup.headers.reduce(
    (oldSum, header) => oldSum + header.colSpan,
    0,
  );

  return colspanSum;
}

export function DataTable<T>({
  data,
  onClickRow,
  loading = false,
  component = DataTable.name,
  allowClientSorting = false,
  className,
  noDataElement,
}: DataTableProps<T>) {
  const maxColSpan: number = useMemo(() => {
    const colspanSums = data.getHeaderGroups()
      .map((headerGroup) => sumColSpans(headerGroup));

    return Math.max(...colspanSums, 1);
  }, [ data ]);

  return (
    <div
      data-component={component}
      className={clsx(
        "w-full align-middle h-full overflow-auto rounded-md border-gray-300 border-solid border relative",
        className,
      )}>
      <table
        className={clsx(
          "min-w-full divide-gray-300",
          loading && "opacity-50",
        )}
      >
        <thead className="bg-gray-50 py-2 top-0 sticky">
          {
            data.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                { headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan} scope="col" className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900">
                    { header.isPlaceholder ? null : (
                      <div className="flex flex-row items-center">
                        { flexRender(header.column.columnDef.header, header.getContext()) }
                        { allowClientSorting && header.column.getCanSort() && <SortButtonGroup
                          toggleSort={header.column.getToggleSortingHandler()}
                          status={header.column.getIsSorted() || undefined}/> }
                      </div>
                    ) }
                  </th>
                )) }
              </tr>
            ))
          }
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          { data.getRowModel().rows.length > 0
            ? data.getRowModel().rows.map((row) => (
              <tr
                onClick={onClickRow ? () => onClickRow(row.original) : undefined}
                className={clsx("hover:bg-gray-50", onClickRow && "cursor-pointer")}
                key={row.id}
              >
                {
                  row.getVisibleCells().map((cell) => (

                    <td key={cell.id}
                      className={`${
                        (cell.column.columnDef.meta
                        && "wrap" in cell.column.columnDef.meta
                        && cell.column.columnDef.meta.wrap) ? "whitespace-normal" : "whitespace-nowrap"
                      } ${
                        (cell.column.columnDef.meta
                        && "noPadding" in cell.column.columnDef.meta
                        && cell.column.columnDef.meta.noPadding) ? "" : "px-4 py-4"
                      } text-sm text-gray-500`}>
                      { flexRender(cell.column.columnDef.cell, cell.getContext()) }
                    </td>
                  ))
                }
              </tr>
            )) : !loading ? <tr>
              <td colSpan={maxColSpan}>
                { noDataElement || "No data" }
              </td>
            </tr> : undefined
          }
        </tbody>
      </table>
      { loading && <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-row justify-center opacity-100" >
        <LoadingSpinner className="m-auto"/>
      </div>
      }

    </div>
  );
}
